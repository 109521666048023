<template>
  <v-bottom-navigation
    shift
    grow
    fixed
    :dark="false"
    :elevation="24"
    background-color="#ffffff"
    v-model="navItem"
  >
    <v-btn
      v-for="(item, index) in navTabList"
      :key="index"
      :value="item.value"
      @click="switchTabTo(index, item)"
    >
      <span>{{ item.name }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "appTab",
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({
    navItem: 1,
    navTabList: [
      {
        value: 1,
        name: "首页",
        icon: "mdi-home",
        route: "/",
      },
      {
        value: 2,
        name: "课程",
        icon: "mdi-book",
        route: "/read",
      },
      // {
      //   value: 3,
      //   name: "专注",
      //   icon: "mdi-chart-bubble",
      //   route: "/focus",
      // },
      // {
      //   value: "03",
      //   name: "我的",
      //   icon: "mdi-account-details",
      //   route: "/about",
      // },
    ],
  }),
  methods: {
    switchTabTo(index, tab) {
      this.navItem = tab.value;
      this.$router.push(tab.route).catch((err) => err);
      this.$emit("switchTab", index);
    },
    initRoute() {
      let path = this.$route.path;
      this.navTabList.forEach((item) => {
        let pattern = "/^\\" + item.route + "/i";
        if (path.match(eval(pattern))) {
          this.navItem = item.value;
          this.$emit("switchTab", item.value);
        }
      });
    },
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initRoute();
      }
    },
  },
  created() {
    this.initRoute();
  },
};
</script>

<style lang="scss" scoped></style>
